import { createRouter, createWebHistory } from "vue-router";
import IssuerHub from "../views/IssuerHub.vue";

//Make acpt use the same slug
let hostname = window.location.hostname.replace("acpt.", "");

if (hostname.startsWith("www.")) {
  // trim the "www." from the URL
  hostname = hostname.slice(4);
}

const hasOwnPublicDomain = !hostname?.includes("gentwo.com") && !hostname?.includes("localhost");
let routes = [];

if (hasOwnPublicDomain) {
  routes = [
    {
      path: "/",
      name: "IssuerHub",
      component: IssuerHub,
      props: { slug: hostname },
    },
    {
      path: "/product/:isin",
      name: "Product",
      component: () => import("../views/IssuerProduct.vue"),
      props: route => ({ slug: hostname, isin: route.params.isin }),
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      redirect: "/not-found",
    },
  ];
} else {
  routes = [
    {
      path: "/:slug",
      name: "IssuerHub",
      component: IssuerHub,
      props: true,
    },
    {
      path: "/:slug/product/:isin",
      name: "Product",
      component: () => import("../views/IssuerProduct.vue"),
      props: true,
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      redirect: "/not-found",
    },
  ];
}

const router = createRouter({
  history: createWebHistory(hasOwnPublicDomain ? import.meta.env.BASE_URL : "/public-issuers"),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});

router.onError(error => {
  if (
    router.pending?.fullPath &&
    (error?.name === "ChunkLoadError" ||
      (error?.name === "TypeError" && error?.message?.includes("dynamically imported module")) ||
      (error?.name === "Error" && error?.message?.includes("Unable to preload CSS")))
  ) {
    // try to reload the desired view
    window.location.assign(router.pending.fullPath);
  } else {
    // else, just reload
    window.location.reload();
  }
});

export default router;
