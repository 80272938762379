<template>
  <DataView
    :value="filteredProducts"
    :layout="layout"
    v-model:first="currentPageOffset"
    :paginator="true"
    :rows="itemsPerPage"
    pageStateKey="IssuerHub.page"
    ref="dataView"
    :loadingRows="6"
    v-if="!notFound"
  >
    <template #header>
      <div class="p-d-flex p-flex-column p-ai-start p-mt-sm-2 p-pt-4">
        <h1 class="p-mt-0 p-mb-2">
          {{ issuer?.name }}
          <Skeleton v-if="!issuer?.name" width="25ch" height="1em" class="p-mt-3" />
        </h1>
        <Tag class="p-tag-light" v-if="products" rounded>
          {{ products.length }} Product{{ products.length !== 1 ? "s" : "" }}
        </Tag>
        <Skeleton v-if="loading" width="10ch" height="2.25em" borderRadius="10rem" />
      </div>

      <div class="p-d-flex p-ai-center p-jc-between p-mt-3 p-fluid">
        <div class="filters p-d-flex p-input-filled p-flex-1 p-mr-4">
          <InputText type="text" v-model="searchQuery" placeholder="Search products" fluid />
        </div>
        <DataViewLayoutOptions v-model="layout" :disabled="loading" />
      </div>

      <h3 v-if="isFiltered && filteredProducts" class="p-mb-2">
        Showing {{ filteredProducts.length }} result{{ filteredProducts.length !== 1 ? "s" : "" }}
      </h3>
    </template>

    <template #table="slotProps">
      <ProductTable
        :data="slotProps.data"
        v-model:first="currentPageOffset"
        :loading="loading"
        :itemsPerPage="itemsPerPage"
        :showWorkflows="false"
        :showIssuer="false"
        :showGrossAssets="false"
        :publicIssuerProduct="true"
      />
    </template>

    <template #grid="slotProps">
      <div class="p-col-12 p-md-6 p-lg-4">
        <ProductCard v-bind="slotProps.data" :showWorkflows="false" :showIssuer="false" />
      </div>
    </template>

    <template #empty>
      <div class="p-d-flex p-flex-column p-ai-center text-color-secondary">
        <icon data="@icon/no-products.svg" width="35%" height="auto" class="p-mt-4" />
        <template v-if="isFiltered">
          <h2 class="p-mb-1">No products found</h2>
          <p>
            Try changing or
            <a class="text-link" @click="clearAllFilters">clear all filters</a>
          </p>
        </template>
        <template v-else>
          <h2 class="p-mb-1">No products yet</h2>
        </template>
      </div>
    </template>
  </DataView>

  <div
    class="p-d-flex p-flex-column p-flex-1 p-ai-center p-jc-center text-color-secondary p-mt-4"
    v-else
  >
    <icon data="@icon/no-products.svg" width="35%" height="auto" class="p-mt-4" />
    <h2 class="p-mb-1">Issuer not found</h2>
    <p>Please try refreshing or try again later</p>
  </div>
</template>

<script>
import { usePublicIssuerStore } from "@/stores/publicissuer";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import Skeleton from "primevue/skeleton";
import DataView from "@/components/containers/DataView";
import DataViewLayoutOptions from "@/components/elements/DataViewLayoutOptions";
import ProductCard from "@/components/product/Card";
import ProductTable from "@/components/product/Table";
import productMixins from "@/mixins/product";

export default {
  name: "IssuerHub",
  mixins: [productMixins],
  components: {
    DataView,
    DataViewLayoutOptions,
    Tag,
    Skeleton,
    ProductCard,
    ProductTable,
    InputText,
  },
  props: {
    slug: String,
  },
  setup: () => {
    return { publicIssuerStore: usePublicIssuerStore() };
  },
  data() {
    return {
      itemsPerPage: 6,
      currentPageOffset:
        sessionStorage.getItem("IssuerHub.layout") === "grid"
          ? JSON.parse(sessionStorage.getItem("IssuerHub.page"))?.first || 0
          : JSON.parse(sessionStorage.getItem("IssuerHub.tableState"))?.first || 0,
      layout: sessionStorage.getItem("IssuerHub.layout") || "grid",
      searchQuery: sessionStorage.getItem("IssuerHub.searchQuery") || "",
      notFound: false,
    };
  },
  computed: {
    issuer() {
      return this.publicIssuerStore?.issuer;
    },
    products() {
      return this.publicIssuerStore?.all;
    },
    loading() {
      return !this.products;
    },
    filteredProducts() {
      const searchQuery = this.searchQuery.trim().toLowerCase();

      sessionStorage.setItem("IssuerHub.searchQuery", searchQuery);

      return this.products?.filter(
        product =>
          product.longName.toLowerCase().includes(searchQuery) ||
          product.isin.toLowerCase().includes(searchQuery) ||
          product.currencyIso.toLowerCase().includes(searchQuery),
      );
    },
    isFiltered() {
      return this.searchQuery !== "";
    },
  },
  watch: {
    layout(newLayout) {
      sessionStorage.setItem("IssuerHub.layout", newLayout);
    },
  },
  async created() {
    try {
      if (!this.issuer?.name) {
        await this.publicIssuerStore.loadIssuer(this.slug);
        // setting the document.title to the issuer name
        this.$root.pageTitle = document.title = this.issuer.name;
      }
    } catch (error) {
      this.notFound = true;
    }
    this.publicIssuerStore.loadAllProducts(this.slug);
  },
  methods: {
    clearAllFilters() {
      this.searchQuery = "";
    },
  },
};
</script>

<style scoped lang="scss">
:deep() {
  .product-card {
    .p-card-header {
      .product-type {
        background: var(--primary-color);
      }
    }
    .p-card-title {
      margin-bottom: 0;
    }
  }
}
</style>
