import openAPIClientAxios from "./plugins/openAPIClientAxios";
import { createApp } from "vue";
import IssuerApp from "./IssuerApp.vue";
import router from "./router/issuer";
import { pinia } from "./stores/issuer";
import dayjsPlugin from "./plugins/dayjs";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import pageTitleMixin from "@/mixins/pageTitle";

import "@yzfe/svgicon/lib/svgicon.css";
import "./assets/scss/issuer-homepage/main.scss";

const app = createApp(IssuerApp)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(openAPIClientAxios)
  .use(pinia)
  .use(router)
  .use(dayjsPlugin)
  .use(PrimeVue, {
    theme: {
      options: {
        cssLayer: {
          name: "primevue",
        },
      },
    },
  })
  .use(ToastService)
  .mixin(pageTitleMixin);

app.mount("#app");
