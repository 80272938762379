<template>
  <main>
    <router-view v-if="acceptDisclaimer" />
  </main>

  <footer>
    <div class="p-d-flex p-jc-between">
      <div class="p-flex-column">
        <p class="p-m-0">© {{ copyrightYear }} {{ issuer?.name }}</p>
        <p class="footer-secondary p-m-0">All rights reserved.</p>
      </div>
    </div>
  </footer>

  <DisclaimerPopup v-model="acceptDisclaimer">
    <p class="p-text-bold">Local access restrictions for the website</p>
    <p>
      The content of this website is intended exclusively for visitors whose place of
      residence/domicile is registered in Switzerland, Germany and Liechtenstein. Visitors from other countries are not
      permitted to access the website.
    </p>
  </DisclaimerPopup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRootStore } from "@/stores/issuer";
import { usePublicIssuerStore } from "@/stores/publicissuer";
import DisclaimerPopup from "@/components/DisclaimerPopup.vue";

export default defineComponent({
  name: "IssuerApp",
  setup: () => {
    return { rootStore: useRootStore(), publicIssuerStore: usePublicIssuerStore() };
  },
  components: {
    DisclaimerPopup,
  },
  title() {
    return this.issuer?.name;
  },
  data() {
    return {
      acceptDisclaimer: false,
      copyrightYear: new Date().getUTCFullYear(),
    };
  },
  computed: {
    isStaging() {
      const hostname = window.location.hostname;

      return !hostname?.includes("gentwo") || !hostname?.includes("localhost");
    },
    issuer() {
      return this.publicIssuerStore?.issuer;
    },
  },
  async created() {
    await this.rootStore.init(this);
  },
});
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: 0 2%;
  max-width: 1500px;
  margin: 0 auto;
}
main {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  padding-bottom: 6rem;
}
</style>
