<template>
  <Dialog
    v-model:visible="visible"
    :closeOnEscape="false"
    :closable="false"
    :contentStyle="{ overflow: 'visible' }"
    :modal="true"
    class="disclaimer-popup"
  >
    <template #header>
      <div class="p-width-100">
        <h3>DISCLAIMER</h3>
      </div>
      <Divider class="p-dialog-divider" />
    </template>

    <div>
      <slot />
    </div>
    <template #footer>
      <Button label="I Acknowledge" @click="accept" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Divider from "primevue/divider";
import { useCookies } from "vue3-cookies";

export default {
  name: "DisclaimerPopup",
  emits: ["update:modelValue"],
  components: { Dialog, Button, Divider },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    modelValue: null,
  },
  data() {
    return {
      visible: false,
      cookie_name: "publicIssuer_local_access_restriction",
    };
  },
  created() {
    const cookie = this.cookies.get(this.cookie_name);
    if (!cookie) {
      this.visible = true;
    } else {
      this.$emit("update:modelValue", true);
    }
  },
  methods: {
    accept() {
      this.cookies.set(this.cookie_name, true, "365d");
      this.$emit("update:modelValue", true);
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.disclaimer-popup.p-dialog {
  width: unset;
  min-width: unset;
  max-width: unset;
  user-select: none;
}
</style>
