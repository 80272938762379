import { createPinia, defineStore, PiniaPluginContext } from "pinia";
import { useApiStore } from "./api";

//const debug = process.env.NODE_ENV !== "production";

export const pinia = createPinia();

pinia.use(({ app, store }: PiniaPluginContext) => {
  store.apiClient = app.config.globalProperties.$apiClient;
});

export const useRootStore = defineStore("RootStore", {
  actions: {
    async init(vue) {
      const api = useApiStore();
      api.init(vue);
    },
  },
});
